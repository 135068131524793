<template>
    <div class="square-logo animate__animated animate__fadeIn">
      <img class="logo" src="@/assets/imgs/logo_square.png">
    </div>    
</template>

<script>
export default {
  name: 'SquareLogo',
  data(){
      return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.square-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  letter-spacing: 1px;
  .logo {
      width: 300px;
      margin-bottom: 200px;
  }
}
</style>
